import React from 'react'

const SvgCase1 = props => (
  <svg id='case1_svg___tuceng_2' viewBox='0 0 168 118' {...props}>
    <defs>
      <linearGradient id='case1_svg___unnamed_33' x1={93} y1={61} x2={143} y2={61} gradientUnits='userSpaceOnUse'>
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={1} stopColor='#252a3a' />
      </linearGradient>
      <linearGradient id='case1_svg___unnamed_33-2' y1={71} y2={71} xlinkHref='#case1_svg___unnamed_33' />
      <linearGradient
        id='case1_svg___unnamed_33-3'
        x1={17.37}
        y1={119.63}
        x2={150.63}
        y2={-13.63}
        xlinkHref='#case1_svg___unnamed_33'
      />
      <linearGradient
        id='case1_svg___unnamed_33-4'
        x1={58}
        y1={117}
        x2={110}
        y2={117}
        xlinkHref='#case1_svg___unnamed_33'
      />
      <linearGradient
        id='case1_svg___unnamed_25'
        x1={76.8}
        y1={119.44}
        x2={90.89}
        y2={105.35}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.2} />
        <stop offset={1} stopColor='#252a3a' stopOpacity={0.8} />
      </linearGradient>
      <style>{'.case1_svg__cls-4{fill:#fd5c1f}'}</style>
    </defs>
    <g id='case1_svg___tuceng_1-2'>
      <path
        className='case1_svg__cls-4'
        d='M49.5 64.32c-1.58 0-2.87 1.29-2.87 2.87s1.29 2.87 2.87 2.87 2.87-1.29 2.87-2.87-1.29-2.87-2.87-2.87zm0 4.43c-.86 0-1.56-.7-1.56-1.56s.7-1.56 1.56-1.56 1.56.7 1.56 1.56-.7 1.56-1.56 1.56zM62.78 58.24c-.86-.5-1.91-.34-2.6.3l-7.21-4.16c.14-.39.23-.8.23-1.24s-.09-.85-.23-1.24l6.52-3.76-.65-1.13-6.52 3.76c-.54-.64-1.29-1.1-2.31-1.25V41.2c1.06-.28 1.72-1.11 1.72-2.11 0-1.22-.99-2.22-2.22-2.22s-2.22.99-2.22 2.22c0 .99.66 1.83 1.71 2.11v8.32c-1.01.15-1.76.61-2.3 1.25l-6.52-3.76-.65 1.13 6.52 3.76c-.14.39-.23.81-.23 1.24s.09.85.23 1.24l-7.21 4.16c-.69-.64-1.74-.8-2.6-.3-1.06.61-1.42 1.97-.81 3.03s1.97 1.42 3.03.81a2.2 2.2 0 001.04-2.4l7.21-4.17c.54.64 1.29 1.1 2.3 1.25V64h1v-7.24c1.02-.15 1.76-.61 2.31-1.25l7.21 4.17c-.21.92.18 1.91 1.04 2.41 1.06.61 2.41.25 3.03-.81.61-1.06.25-2.41-.81-3.03zM35.9 48.59c.44.25.93.38 1.43.38 1.02 0 1.98-.55 2.49-1.43.79-1.37.32-3.13-1.05-3.92-1.32-.76-3.16-.27-3.92 1.05a2.874 2.874 0 001.05 3.92zm.08-3.27c.28-.48.8-.78 1.36-.78a1.557 1.557 0 011.35 2.34c-.42.72-1.41.99-2.13.57-.75-.43-1-1.39-.57-2.13zM60.24 43.62c-.66.38-1.14 1-1.34 1.74-.2.74-.1 1.51.29 2.18a2.86 2.86 0 002.49 1.43 2.86 2.86 0 002.77-2.12c.2-.74.1-1.51-.29-2.18-.76-1.32-2.59-1.82-3.92-1.05zm2.94 2.89c-.11.4-.37.74-.73.95-.72.42-1.72.15-2.13-.57a1.57 1.57 0 01-.16-1.18c.11-.4.37-.74.73-.95.24-.14.51-.21.78-.21.56 0 1.08.3 1.36.78.21.36.26.78.16 1.18z'
      />
      <path
        className='case1_svg__cls-4'
        d='M75 41.6c0-1.87-1.33-4.16-2.95-5.08L52.94 25.63c-1.61-.92-4.27-.92-5.87 0L27.96 36.52c-1.62.93-2.95 3.21-2.95 5.08v23.01c0 1.87 1.33 4.16 2.95 5.08l19.11 10.89c.8.46 1.85.71 2.94.71s2.13-.25 2.94-.71l19.11-10.89c1.62-.93 2.95-3.21 2.95-5.08V41.6zm-3.62 26.18L51.64 79.03c-.9.51-2.37.51-3.27 0L28.63 67.78c-.89-.51-1.62-1.77-1.62-2.79V41.23c0-1.03.73-2.28 1.62-2.79l19.7-11.22c.42-.24 1.03-.37 1.68-.37s1.26.14 1.68.37l19.7 11.22c.89.51 1.62 1.77 1.62 2.79v23.76c0 1.03-.73 2.29-1.62 2.79z'
      />
      <path fill='none' strokeMiterlimit={10} strokeWidth={2} stroke='url(#case1_svg___unnamed_33)' d='M93 61h50' />
      <path stroke='url(#case1_svg___unnamed_33-2)' fill='none' strokeMiterlimit={10} strokeWidth={2} d='M93 71h50' />
      <g>
        <path
          d='M161.63 2c2.41 0 4.37 1.96 4.37 4.37v93.26c0 2.41-1.96 4.37-4.37 4.37H6.37C3.96 104 2 102.04 2 99.63V6.37C2 3.96 3.96 2 6.37 2h155.26m0-2H6.37C2.85 0 0 2.85 0 6.37v93.26c0 3.52 2.85 6.37 6.37 6.37h155.26c3.52 0 6.37-2.85 6.37-6.37V6.37c0-3.52-2.85-6.37-6.37-6.37z'
          fill='url(#case1_svg___unnamed_33-3)'
        />
        <path
          stroke='url(#case1_svg___unnamed_33-4)'
          fill='none'
          strokeMiterlimit={10}
          strokeWidth={2}
          d='M58 117h52'
        />
        <path fill='url(#case1_svg___unnamed_25)' d='M94.34 116H73.35l2.8-10h15.39l2.8 10z' />
      </g>
      <g>
        <path
          className='case1_svg__cls-4'
          d='M104.4 42.5h-8.44v8.72h-4.39V29.89h13.89v3.56h-9.49v5.51h8.44v3.54zM125.55 33.44h-6.53v17.77h-4.39V33.44h-6.45v-3.56h17.37v3.56zM133.65 43.7v7.51h-4.39V29.88h8.32c1.26 0 2.38.18 3.38.53.99.35 1.83.84 2.52 1.47s1.21 1.38 1.57 2.24c.36.87.54 1.8.54 2.82s-.18 1.97-.54 2.8-.89 1.54-1.57 2.12c-.69.59-1.53 1.04-2.52 1.36-.99.32-2.12.48-3.38.48h-3.93zm0-3.56h3.93c.62 0 1.16-.08 1.62-.23.45-.16.82-.37 1.11-.65.29-.28.5-.61.64-1s.21-.82.21-1.27-.07-.9-.21-1.33c-.14-.42-.35-.8-.64-1.13-.29-.33-.66-.59-1.11-.78-.46-.2-.99-.29-1.62-.29h-3.93v6.69z'
        />
      </g>
    </g>
  </svg>
)

export default SvgCase1

import React from 'react'

const SvgMassFeature4 = props => (
  <svg id='mass_feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.mass_feature4_svg__st0{fill:#334966}'}</style>
    <g id='mass_feature4_svg__\u5F39\u6027\u8BA1\u7B97-48x48'>
      <path
        id='mass_feature4_svg__Fill-1_3_'
        className='mass_feature4_svg__st0'
        d='M24.67 1c-13.25 0-24 10.74-24 24s10.74 24 24 24 24-10.74 24-24-10.75-24-24-24m0 2c12.13 0 22 9.87 22 22s-9.87 22-22 22-22-9.87-22-22 9.87-22 22-22'
      />
      <path id='mass_feature4_svg__Polygon-3' fill='#fd5c1f' d='M24.67 12l11 6.5v13l-11 6.5-11-6.5v-13z' />
      <path
        id='mass_feature4_svg__Fill-1_2_'
        className='mass_feature4_svg__st0'
        d='M24.67 11l-6 3.6 1.94 1.17 3.06-1.84V19h2v-5.07l3.06 1.84 1.94-1.17z'
      />
      <path
        id='mass_feature4_svg__Fill-2_3_'
        className='mass_feature4_svg__st0'
        d='M34.95 27.2v2.46l-4.2-2.42-1.08 1.69 4.51 2.6-3.23 1.94v2.33l6-3.6V26z'
      />
      <path
        id='mass_feature4_svg__Fill-3_2_'
        className='mass_feature4_svg__st0'
        d='M12.67 32.2l6 3.6v-2.33l-3.23-1.94 4.51-2.6-1.08-1.69-4.2 2.42V27.2l-2-1.2z'
      />
    </g>
  </svg>
)

export default SvgMassFeature4

import React from 'react'

const SvgMassFeature1 = props => (
  <svg id='mass_feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.mass_feature1_svg__st1{fill:#334966}'}</style>
    <g id='mass_feature1_svg__\u5185\u6838\u4F18\u5316-48x48'>
      <path
        id='mass_feature1_svg__Rectangle-86'
        d='M6 11.41v27.17L11.41 44h27.17L44 38.59V11.41L38.59 6H11.41L6 11.41z'
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
      <path id='mass_feature1_svg__Rectangle-87' className='mass_feature1_svg__st1' d='M24 1h2v6h-2z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-3' className='mass_feature1_svg__st1' d='M24 43h2v6h-2z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy' className='mass_feature1_svg__st1' d='M30 1h2v6h-2z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-4' className='mass_feature1_svg__st1' d='M30 43h2v6h-2z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-6' className='mass_feature1_svg__st1' d='M1 18h6v2H1z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-9' className='mass_feature1_svg__st1' d='M43 18h6v2h-6z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-7' className='mass_feature1_svg__st1' d='M1 24h6v2H1z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-10' className='mass_feature1_svg__st1' d='M43 24h6v2h-6z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-8' className='mass_feature1_svg__st1' d='M1 30h6v2H1z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-11' className='mass_feature1_svg__st1' d='M43 30h6v2h-6z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-2' className='mass_feature1_svg__st1' d='M18 1h2v6h-2z' />
      <path id='mass_feature1_svg__Rectangle-87-Copy-5' className='mass_feature1_svg__st1' d='M18 43h2v6h-2z' />
      <path id='mass_feature1_svg__Rectangle-88' fill='#fd5c1f' d='M15 15h20v20H15z' />
    </g>
  </svg>
)

export default SvgMassFeature1

import React from 'react'

const SvgMassFeature2 = props => (
  <svg id='mass_feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.mass_feature2_svg__st0{fill:#334966}.mass_feature2_svg__st1{fill:#fd5c1f}'}</style>
    <g id='mass_feature2_svg__\u591A\u7EF4\u62A5\u8868\u8FD0\u8425-48x48'>
      <path
        id='mass_feature2_svg__Rectangle-34'
        className='mass_feature2_svg__st0'
        d='M3 7.24v36h44v-36H3zm-2-2h48v40H1v-40z'
      />
      <circle id='mass_feature2_svg__Oval-16' className='mass_feature2_svg__st1' cx={22.5} cy={26.74} r={9.5} />
      <path
        id='mass_feature2_svg__Combined-Shape_25_'
        d='M22 14.28v11.96H10.04c.48-6.38 5.58-11.48 11.96-11.96z'
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
      <path id='mass_feature2_svg__Rectangle-35' className='mass_feature2_svg__st0' d='M37 13.24h4v2h-4z' />
      <path id='mass_feature2_svg__Rectangle-35-Copy' className='mass_feature2_svg__st1' d='M37 20.24h4v2h-4z' />
      <path id='mass_feature2_svg__Rectangle-35-Copy-2' className='mass_feature2_svg__st1' d='M37 27.24h4v2h-4z' />
      <path id='mass_feature2_svg__Rectangle-35-Copy-3' className='mass_feature2_svg__st0' d='M9 35.24h32v2H9z' />
    </g>
  </svg>
)

export default SvgMassFeature2

import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, H4 } from '@raysync/common/components'

import Layout from '../../components/layout'

import {
  SectionDesc,
  FeatureSection,
  Section,
  SolutionDivider,
  ApplicationSection,
  ApplicationContainer,
  ApplicationItem,
} from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/mass-feature1'
import Feature2 from '@raysync/common/components/svg/mass-feature2'
import Feature3 from '@raysync/common/components/svg/mass-feature3'
import Feature4 from '@raysync/common/components/svg/mass-feature4'
import App1 from '@raysync/common/components/svg/solutions/files/case1'
import App2 from '@raysync/common/components/svg/mass-app2'
import App3 from '@raysync/common/components/svg/mass-app3'
import App4 from '@raysync/common/components/svg/mass-app4'
import Banner from '@raysync/common/components/svg/solutions/applications/mass-data-banner'

class DataTransfer extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('solution1.feature.item1.title'),
        desc: intl.get('solution1.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('solution1.feature.item2.title'),
        desc: intl.get('solution1.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('solution1.feature.item3.title'),
        desc: intl.get('solution1.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('solution1.feature.item4.title'),
        desc: intl.get('solution1.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    applicationList: [
      {
        point: '替代传统FTP',
        svg: <App1 />,
      },
      {
        point: intl.get('solution1.scenes.point2'),
        svg: <App2 />,
      },
      {
        point: intl.get('solution1.scenes.point3'),
        svg: <App3 />,
      },
      {
        point: intl.get('solution1.scenes.point4'),
        svg: <App4 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList, applicationList } = this.state
    return (
      <Layout pageType='solution1' location={location}>
        <Swiper>
          <SolutionBanner type='solution1' pic={<Banner />} />
        </Swiper>
        <Section>
          <CommonTitle>{intl.get('solution1.section.title')}</CommonTitle>
          <SectionDesc top={25} bottom={18}>
            {intl.get('solution1.section.desc1')}
          </SectionDesc>
          <SectionDesc>{intl.get('solution1.section.desc2')}</SectionDesc>
          <SectionDesc>{intl.get('solution1.section.desc3')}</SectionDesc>
          <SectionDesc bottom={48}>{intl.get('solution1.section.desc4')}</SectionDesc>
          <SolutionDivider />
        </Section>
        <FeatureSection>
          <CommonTitle>{intl.get('solution1.feature.title')}</CommonTitle>
          <SectionDesc top={12} bottom={26}>
            {intl.get('solution1.feature.desc')}
          </SectionDesc>
          <List data={featureList} />
        </FeatureSection>
        <ApplicationSection>
          <CommonTitle>{intl.get('solution1.scenes.title')}</CommonTitle>
          <ApplicationContainer>
            {applicationList.map((l, i) => (
              <ApplicationItem key={i}>
                <div>{l.svg}</div>
                <H4>{l.point}</H4>
              </ApplicationItem>
            ))}
          </ApplicationContainer>
        </ApplicationSection>
        <Swiper>
          <InviteBanner type='solution1' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default DataTransfer

export const query = graphql`
  query {
    adBanner: file(relativePath: { regex: "/industry/ad_banner/" }) {
      ...fluidImage
    }
  }
`

import React from 'react'

const SvgMassFeature3 = props => (
  <svg id='mass_feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {'.mass_feature3_svg__st0{fill:#fd5c1f}.mass_feature3_svg__st1{fill:none;stroke:#334966;stroke-width:2}'}
    </style>
    <path className='mass_feature3_svg__st0' d='M26.95 23.82h-4l.9-16c0-.6.5-1 1.1-1 .6 0 1 .4 1.1 1l.9 16z' />
    <path
      id='mass_feature3_svg__XMLID_40_'
      className='mass_feature3_svg__st0'
      d='M25.15 23.32l-2.5 3.1-11.9-10.8c-.4-.4-.5-1-.1-1.5.4-.4 1-.5 1.4-.2l13.1 9.4z'
    />
    <path
      id='mass_feature3_svg__XMLID_39_'
      className='mass_feature3_svg__st0'
      d='M24.75 23.32l2.5 3.1 11.8-10.8c.4-.4.5-1 .1-1.5-.4-.4-1-.5-1.4-.2l-13 9.4z'
    />
    <path
      id='mass_feature3_svg__XMLID_18_'
      className='mass_feature3_svg__st0'
      d='M23.15 24.42l.9 3.9-15.9 2.5c-.6.1-1.1-.3-1.2-.8-.1-.5.2-1.1.7-1.2l15.5-4.4z'
    />
    <path
      id='mass_feature3_svg__XMLID_17_'
      className='mass_feature3_svg__st0'
      d='M26.75 24.42l-.9 3.9 15.9 2.5c.6.1 1.1-.3 1.2-.8.1-.5-.2-1.1-.7-1.2l-15.5-4.4z'
    />
    <path
      id='mass_feature3_svg__XMLID_20_'
      className='mass_feature3_svg__st0'
      d='M22.85 26.52l3.6 1.8-7.9 13.9c-.3.5-.9.7-1.4.4-.5-.2-.7-.8-.5-1.4l6.2-14.7z'
    />
    <path
      id='mass_feature3_svg__XMLID_19_'
      className='mass_feature3_svg__st0'
      d='M27.05 26.52l-3.6 1.8 7.9 13.9c.3.5.9.7 1.4.4.5-.2.7-.8.5-1.4l-6.2-14.7z'
    />
    <path
      className='mass_feature3_svg__st1'
      d='M24.05 2.22l-16.7 8.3c-.5.3-.9.8-1.1 1.3l-4.1 18.7c-.1.6 0 1.2.4 1.7l11.6 14.9c.4.5 1 .8 1.6.8h18.5c.6 0 1.2-.3 1.6-.8l11.6-14.9c.4-.5.5-1.1.4-1.7l-4.2-18.7c-.1-.6-.5-1.1-1.1-1.4l-16.7-8.2c-.6-.2-1.2-.2-1.8 0z'
    />
    <circle id='mass_feature3_svg__Oval-7_16_' className='mass_feature3_svg__st1' cx={24.95} cy={25.82} r={12} />
    <g id='mass_feature3_svg__Oval-7_13_'>
      <path
        className='mass_feature3_svg__st0'
        d='M24.95 24.82c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1m0-2c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z'
      />
    </g>
    <circle id='mass_feature3_svg__Oval-7_6_' cx={24.95} cy={25.82} r={2} fill='#fff' />
  </svg>
)

export default SvgMassFeature3
